import {
  Stack,
  Button,
  IconButton,
  Container,
  Drawer,
  Box,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Logo from "../../assets/images/logo-white.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Nav, Navbar } from "react-bootstrap";
import { icons } from "../../assets/js/icons";
import Select from "react-dropdown-select";
import { Cookies } from "react-cookie";
import i18next from "i18next";

function Header() {
  const cookie = new Cookies();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [drawer, setDrawer] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);
  const languages = [
    {
      dir: "rtl",
      code: "fa",
      name: "فارسی",
      country_code: "IR",
    },
    {
      dir: "ltr",
      code: "en",
      name: "English",
      country_code: "EN",
    },
  ];
  // eslint-disable-next-line

  const ChangeLanguage = (value) => {
    i18next.changeLanguage(value);
    const currentLangCode = cookie.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLangCode);
    document.body.dir = currentLanguage.dir;
  };

  useEffect(() => {
    const currentLangCode = cookie.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLangCode);
    setSelectedLang([...selectedLang, currentLanguage]);
  }, [navigate]);

  return (
    <div id="Header">
      <header className="bg-primary-main">
        <div className="bg-primary-main py-2 text-white info-box md:hidden">
          <Container>
            <Stack direction="row" spacing={2}>
              <IconButton
                className="flex lg:hidden text-white"
                onClick={() => {
                  setDrawer(true);
                }}
              >
                {<icons.MuiMenu />}
              </IconButton>
            </Stack>
          </Container>
        </div>
        <div className="header-main hidden md:block">
          <Navbar className="custom-header-navbar p-0">
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="w-100 mx-auto align-items-center justify-content-between gap-[2px] md:gap-2">
                  <Navbar.Brand as={Link} className="p-0 m-0" to="/">
                    <img
                      className="header-logo w-full max-w-[120px]"
                      src={Logo}
                      alt="img"
                      width={120}
                    />
                  </Navbar.Brand>
                  <div className="d-flex">
                    {!cookie.get("auth") ? (
                      <Fragment>
                        <Link to="/login" className="nav-link text-uppercase">
                          {t("l_btn")}{" "}
                        </Link>
                        <Link to="/signup" className="nav-link text-uppercase">
                          {t("s_text")}{" "}
                        </Link>
                        <Link to="/pricing" className="nav-link text-uppercase">
                          {"Pricing"}
                        </Link>
                      </Fragment>
                    ) : (
                      <Link
                        to="/user/chat/outside"
                        className="nav-link text-uppercase"
                      >
                        Home
                      </Link>
                    )}
                    {/* <Link
                      to={"/user/chat/account"}
                      className="nav-link text-uppercase"
                    >
                      Account{" "}
                    </Link> */}
                    <Link to={"/about"} className="nav-link text-uppercase">
                      About Us
                    </Link>
                    <Link to={"/contact"} className="nav-link text-uppercase">
                      Contact Us
                    </Link>
                    <Link to={"/faqs"} className="nav-link text-uppercase">
                      FAQs
                    </Link>
                    <Select
                      className="shadow-sm multiselect-dropdown me-2"
                      style={{ width: 150, maxWidth: 150, zIndex: 9999 }}
                      options={languages}
                      values={selectedLang}
                      searchable={true}
                      placeholder="Language options"
                      labelField="name"
                      valueField="code"
                      onChange={(value) => {
                        ChangeLanguage(value.map(({ code }) => code)[0]);
                      }}
                    />
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>

      <Drawer
        open={drawer}
        anchor="left"
        onClose={() => setDrawer(false)}
        className="block lg:hidden"
      >
        <Box className="flex gap-2 items-center p-[12px] bg-primary-light">
          <Button
            LinkComponent={Link}
            to="/login"
            className="text-white normal-case p-0"
          >
            <img
              alt="logo"
              src={Logo}
              className="max-w-[120px] w-full object-cover p-[2px]"
            />
          </Button>
          <IconButton
            className="text-white"
            onClick={() => {
              setDrawer(false);
            }}
          >
            {<icons.MuiLogout className="rotate-180" />}
          </IconButton>
        </Box>
        <Stack
          spacing={1}
          className="px-[12px] bg-primary-light h-full text-white overflow-auto"
        >
          {!cookie.get("auth") ? (
            <Fragment>
              <Link
                to="/login"
                className="p-[8px] border-transparent rounded-md text-white leading-[1] hover:bg-primary-main"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="p-[8px] border-transparent rounded-md text-white leading-[1] hover:bg-primary-main"
              >
                Sign Up
              </Link>
            </Fragment>
          ) : (
            <Link
              to="/user/chat/outside"
              className="p-[8px] border-transparent rounded-md text-white leading-[1] hover:bg-primary-main"
            >
              Home
            </Link>
          )}
          <Link
            to={"/user/chat/account"}
            className="p-[8px] border-transparent rounded-md text-white leading-[1] hover:bg-primary-main"
          >
            Account
          </Link>
          <Select
            className="shadow-sm multiselect-dropdown me-2"
            style={{ width: 150, maxWidth: 150, zIndex: 9999 }}
            options={languages}
            values={selectedLang}
            searchable={true}
            placeholder="Language options"
            labelField="name"
            valueField="code"
            onChange={(value) => {
              ChangeLanguage(value.map(({ code }) => code)[0]);
            }}
          />
        </Stack>
      </Drawer>
    </div>
  );
}

export default Header;
