import {
  Button as MuiBtn,
  Menu,
  MenuItem,
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import {
  AddImage,
  AddImageProject,
} from "../../redux/slices/ImageProjectSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AddArticleProject } from "../../redux/slices/ArticleProjectSlice";
import { AddSocialProject } from "../../redux/slices/SocialProjectSlice";
import { AddEmailProject } from "../../redux/slices/EmailProjectSlice";
import { AddChatProject } from "../../redux/slices/ChatProjectSlice";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { AddDocProject } from "../../redux/slices/DocProjectSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { headers, checktoken, BASEURL } from "../../utils/helper";
import { AddHRProject } from "../../redux/slices/HRUploadSlice";
import { addHistory } from "../../redux/slices/AppHistorySlice";
import { addPrompt } from "../../redux/slices/ChatPromptSlice";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { addMessage } from "../../redux/slices/MessageSlice";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { removeAuth } from "../../redux/slices/AuthSlice";
import { openModal } from "../../redux/slices/ModalSlice";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import ButtonSpinner from "../common/ButtonSpinner";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageModal from "../common/LanguageModal";
import ReportModal from "../common/ReportModal";
import { useTranslation } from "react-i18next";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useTour } from "@reactour/tour";
import { Cookies } from "react-cookie";
import i18next from "i18next";
import axios from "axios";
import {
  AddTextToVideoProject,
  DeleteTextToVideoProject,
} from "../../redux/slices/TextToVideoSlice";
import { AddRephraseProject } from "../../redux/slices/RephraseProjectSlice";
import { AddTranslationProject } from "../../redux/slices/TranslationProjectSlice";
import { AddTranscribeImageProject } from "../../redux/slices/TranscribeImageSlice";

const Header = () => {
  const { _id, image, currentplan } = useSelector((state) => state.AuthSlice);
  const [selectedLang, setSelectedLang] = useState([]);
  const [langmodal, setLangmodal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [project, setProject] = useState();
  const [modal, setModal] = useState(false);
  const [text, setText] = useState("");
  const { pathname } = useLocation();
  const { type, id } = useParams();
  const { setIsOpen } = useTour();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const open = Boolean(anchor);
  const languages = [
    {
      dir: "rtl",
      code: "fa",
      name: "فارسی",
      country_code: "IR",
    },
    {
      dir: "ltr",
      code: "en",
      name: "English",
      country_code: "EN",
    },
  ];

  const ChangeLanguage = (value) => {
    i18next.changeLanguage(value);
    const currentLangCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLangCode);
    document.body.dir = currentLanguage.dir;
  };

  const logOut = (e) => {
    setSpinner(true);
    e.preventDefault();
    axios
      .post(
        `${BASEURL}/logout`,
        { id: _id },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data?.success === true) {
          cookies.remove("auth", { path: "/" });
          cookies.remove("refreshToken", { path: "/" });
          dispatch(addPrompt([]));
          dispatch(removeAuth({}));
          dispatch(addHistory([]));
          dispatch(addMessage({}));
          dispatch(AddImage(null));
          dispatch(AddHRProject({}));
          dispatch(openModal(false));
          dispatch(AddDocProject({}));
          dispatch(AddChatProject({}));
          dispatch(AddEmailProject({}));
          dispatch(AddImageProject({}));
          dispatch(AddSocialProject({}));
          dispatch(AddArticleProject({}));
          dispatch(AddRephraseProject({}));
          dispatch(AddTextToVideoProject({}));
          dispatch(DeleteTextToVideoProject());
          dispatch(AddTranslationProject({}));
          dispatch(AddTranscribeImageProject({}));

          // persistor.purge()
          navigate(res.data.redirect);
        }
        setSpinner(false);
      })
      .catch((err) => {
        checktoken(err);
        setSpinner(false);
      });
  };

  const StartUserTour = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setLangmodal(false);
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const getProject = (id, type) => {
    axios
      .get(`${BASEURL}/user/project/info/${id}/${type}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setProject(res.data.data.title);
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  useEffect(() => {
    const currentLangCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLangCode);
    setSelectedLang([...selectedLang, currentLanguage]);
    setAnchor(null);
    if (type && id) {
      getProject(id, type);
    }
  }, [navigate]);

  return (
    <div>
      <div
        id="chat-header"
        className="d-flex justify-content-between p-2 px-sm-3 shadow-sm align-items-center flex-wrap position-relative"
        style={{ zIndex: 9 }}
      >
        <ReportModal open={modal} handleClose={handleModalClose} />
        <LanguageModal open={langmodal} text={text} handleClose={handleClose} />
        <div className="d-flex d-md-block justify-content-between flex-grow-1 mb-3 mb-sm-0">
          <div className="flex gap-4 items-center">
            <button
              className="bg-secondary-main text-white ff-lato rounded-lg"
              onClick={() => {
                window.history.back();
              }}
            >
              <NavigateBeforeIcon />
            </button>
            <Typography
              variant="h3"
              className="text-xl ff-lato font-medium text-gray-600 page-heading"
            >
              {type && id && `History: ${project}`}

              {pathname === "/user/chat/projects/social" && t("social_history")}

              {pathname === "/user/chat/account" && t("acc_text")}
              {pathname === "/user/chat/outside" && t("executive")}
              {pathname === "/user/chat/rephrase" && t("rephrase")}
              {pathname === "/user/chat/emails" && t("email_temp")}
              {pathname === "/user/chat/scrap-list" && t("doc_list")}
              {pathname === "/user/chat/hruploads" && t("hr_uploads")}
              {pathname === "/user/chat/scrapping" && t("doc_reader")}
              {pathname === "/user/chat/profile" && t("profile_text")}
              {pathname === "/user/chat/translator" && t("translator")}
              {pathname === "/user/chat/projects" && t("project_pool")}
              {pathname === "/user/chat/articles" && t("article_writer")}
              {pathname === "/user/chat/scrap-reader" && t("doc_reader")}
              {pathname === "/user/chat/team-members" && t("team_members")}
              {pathname === "/user/chat/social-media" && t("social_media")}
              {pathname === "/user/chat/image-generator" && t("img_generator")}
            </Typography>
          </div>

          {window.innerWidth < 600 && (
            <Fragment>
              <MuiBtn
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="p-0"
                aria-expanded={open ? "true" : undefined}
                style={{ minWidth: "40px" }}
                onClick={(e) => {
                  setAnchor(e.currentTarget);
                }}
              >
                {image === null ? (
                  <AccountCircleIcon className="text-white fs-2" />
                ) : (
                  <Avatar src={image} sx={{ height: 30, width: 30 }} />
                )}
              </MuiBtn>
              <Menu
                sx={{ padding: 0 }}
                id="basic-menu"
                anchorEl={anchor}
                open={open}
                onClose={() => {
                  setAnchor(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Stack>
                  <MenuItem>
                    <Link to="/user/chat/profile" className="a-link">
                      <PersonIcon className="me-1" /> {t("proflie_text")}{" "}
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/user/chat/account" className="a-link">
                      <ManageAccountsIcon className="me-1" /> {t("acc_text")}
                    </Link>
                  </MenuItem>
                  <hr className="dropdown-devider m-0" />
                  <MenuItem className="a-link" onClick={logOut}>
                    <LogoutIcon className="me-1" /> {t("logout_text")}{" "}
                    {spinner === true && <ButtonSpinner />}
                  </MenuItem>
                </Stack>
              </Menu>
            </Fragment>
          )}
        </div>
        <Stack direction="row" spacing={3} className="justify-end">
          <div className="d-flex flex-wrap">
            {/* <Select
            className="shadow-sm multiselect-dropdown me-2"
            style={{ width: 150, maxWidth: 150, zIndex: 9999 }}
            options={languages}
            values={selectedLang}
            searchable={true}
            placeholder="Language options"
            labelField="name"
            valueField="code"
            onChange={(value) => { ChangeLanguage(value.map(({ code }) => code)[0]) }} /> */}
            {/* {currentplan?.plankey === "execs_pro_monthly" ||
          currentplan?.plankey === "execs_pro_semianual" ||
          currentplan?.plankey === "execs_pro_yearly" ? (
            <Button
              className="bg-primary-light hover:bg-primary-main text-white ff-lato ms-1 mb-1 mb-sm-0"
              startIcon={<GroupAddIcon />}
              LinkComponent={Link}
              to="/user/chat/team-members"
            >
              {t("team_members")}
            </Button>
          ) : (
            <Button
              className="bg-primary-light hover:bg-primary-main text-white ff-lato ms-1 mb-1 mb-sm-0"
              startIcon={<GroupAddIcon />}
              LinkComponent={Link}
              to="/pricing"
            >
              {t("team_members")}
            </Button>
          )} */}

            <Button
              className="bg-secondary-main text-black ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/about"
            >
              About Us
            </Button>
            <Button
              className="bg-secondary-main text-black ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/contact"
            >
              Contact Us
              {/* {currentplan ? t("upgrade_text") : t("membership")} */}
            </Button>
            <Button
              className="bg-secondary-main text-black ff-lato ms-1"
              // startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/faqs"
            >
              FAQs
              {/* {currentplan ? t("upgrade_text") : t("membership")} */}
            </Button>
            <Button
              className="bg-secondary-main text-black ff-lato ms-1 mb-1 mb-sm-0"
              // startIcon={<AttachMoneyIcon />}
              onClick={() => {
                setModal(true);
              }}
            >
              {t("get_credit")}
            </Button>
            <Button
              className="bg-secondary-main text-black ff-lato ms-1 mb-1 mb-sm-0"
              startIcon={<CreditCardIcon />}
              LinkComponent={Link}
              to="/pricing"
            >
              {currentplan ? t("upgrade_text") : t("membership")}
            </Button>

            {window.innerWidth > 600 && (
              <Fragment>
                <MuiBtn
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  className="p-0"
                  aria-expanded={open ? "true" : undefined}
                  style={{ minWidth: "40px" }}
                  onClick={(e) => {
                    setAnchor(e.currentTarget);
                  }}
                >
                  {image === null ? (
                    <AccountCircleIcon className="text-white fs-2" />
                  ) : (
                    <Avatar src={image} sx={{ height: 30, width: 30 }} />
                  )}
                </MuiBtn>
                <Menu
                  sx={{ padding: 0 }}
                  id="basic-menu"
                  anchorEl={anchor}
                  open={open}
                  onClose={() => {
                    setAnchor(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Stack>
                    <MenuItem>
                      <Link to="/user/chat/profile" className="a-link">
                        <PersonIcon className="me-1" fontSize="inherit" />{" "}
                        {t("profile_text")}{" "}
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/user/chat/account" className="a-link">
                        <ManageAccountsIcon
                          className="me-1"
                          fontSize="inherit"
                        />{" "}
                        {t("acc_text")}
                      </Link>
                    </MenuItem>
                    <hr className="dropdown-devider m-0" />
                    <MenuItem className="a-link" onClick={logOut}>
                      <LogoutIcon className="me-1" fontSize="inherit" />{" "}
                      {t("logout_text")} {spinner === true && <ButtonSpinner />}
                    </MenuItem>
                  </Stack>
                </Menu>
              </Fragment>
            )}
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default Header;
