import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { Link, useNavigate } from "react-router-dom";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import TranslateIcon from "@mui/icons-material/Translate";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import Logo from "../../../assets/images/logo-white.png";
import CustomTooltip from "../../../components/common/CustomTooltip";
import InboxIcon from "@mui/icons-material/Inbox";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { icons } from "../../../assets/js/icons";
import ImageIcon from "@mui/icons-material/Image";
import axios from "axios";
import { useEffect, useState } from "react";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import { Button } from "react-bootstrap";
import AnimateHeight from "react-animate-height";

const {
  MuiUpArrow,
  MuiDownArrow,
  MuiResearch,
  MuiArticle,
  MuiHistory,
  MuiDocumentScanner,
  Insights,
} = icons;

function LandingSidebar({ closeFun, id, setId }) {
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const [height2, setHeight2] = useState(0);
  const navigate = useNavigate();

  const [prompts, setPompts] = useState([]);

  const FeaturedContent = () => {
    axios
      .get(`${BASEURL}/user/featured/content/list`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setPompts(res.data.data);
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  useEffect(() => {
    FeaturedContent();
  }, [navigate]);

  return (
    <Box id="ChatSidebar" style={{ width: "250px" }} className="h-full">
      <div className="logo-sec px-4 sticky-sm-top border-bottom mb-2">
        <div className="text-white d-flex align-items-center">
          <img className="m-auto" src={Logo} alt="img" />
          <IconButton className="text-white flex md:hidden" onClick={closeFun}>
            {<icons.MuiLogout className="rotate-180" />}
          </IconButton>
        </div>
      </div>
      <div className="body-sec px-3 d-flex flex-column gap-2">
        <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
          <span>{MuiResearch}</span>
          <span>{t("executive")}</span>
        </Link>
        <CustomTooltip
          title="Essay writer"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>{MuiArticle}</span>
              <span>{t("article_writer")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Upload the book, article and link in any language and ask about its content in Farsi"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>{<MuiDocumentScanner />}</span>
              <span>{t("doc_reader")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Social Media"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <ConnectWithoutContactIcon />
              </span>
              <span>{"Social Media"}</span>
            </Link>
          }
        />

        <CustomTooltip
          title="Translator of 80 languages"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <TranslateIcon />
              </span>
              <span>{t("translator")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Image Transcription"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <ImageIcon />
              </span>
              <span>{"Image Transcription"}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Professional text reconstruction"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <BorderColorIcon />
              </span>
              <span>{t("rephrase")}</span>
            </Link>
          }
        />
        <CustomTooltip
          // title="Generate email text"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <InboxIcon />
              </span>
              <span>{t("email_temp")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Upload, search and summarize resumes"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <UploadFileIcon />
              </span>
              <span>{t("hr_uploads")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Convert text to image"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>
                <BurstModeIcon />
              </span>
              <span>{t("img_generator")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Text to Video"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded nine-step`} to="/signup">
              <span>
                <SwitchVideoIcon />
              </span>
              <span>{t("text_video")}</span>
              {/* <span className="tag-text">{t("coming_soon")}</span> */}
            </Link>
          }
        />
        <CustomTooltip
          title="Text to Voice"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded nine-step`} to="/signup">
              <span>
                <GraphicEqIcon />
              </span>
              <span>{t("text_voice")}</span>
              <span className="tag-text">{t("coming_soon")}</span>
            </Link>
          }
        />
        <CustomTooltip
          title="Sorted history of all your works"
          arrow
          placement="right"
          content={
            <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
              <span>{MuiHistory}</span>
              <span>{t("project_pool")}</span>
            </Link>
          }
        />

        <div
          className="seventh-step"
          // onMouseLeave={() => setHeight2(0)}
        >
          <Button
            aria-expanded={height2 !== 0}
            aria-controls="example-panel"
            className="accor-btn menu-item p-2 rounded w-100"
            onClick={() => setHeight2(height2 === 0 ? "auto" : 0)}
          >
            <div className="d-flex w-100w-100">
              <span>{Insights}</span>
              <span className="col flex-grow-1">{t("business_in")}</span>
              {height2 === 0 ? (
                <span>{MuiDownArrow}</span>
              ) : (
                <span>{MuiUpArrow}</span>
              )}
            </div>
          </Button>
          <AnimateHeight
            id="example-panel"
            duration={500}
            className="accor mb-3"
            height={height2}
          >
            <div className="d-flex">
              <input
                type="text"
                placeholder="Search prompts"
                className="form-control"
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  // SearchPromptForUser();
                }}
              />
            </div>
            {prompts.length > 0 &&
              prompts.map((item, index) => (
                <Link
                  key={index}
                  to={`/insight/${item._id}`}
                  onClick={() => {
                    console.log("navigating.../");
                    // navigate();
                    setId(item._id);
                  }}
                  // to={`/user/chat/prompt/${item._id}`}
                  className={`menu-item w-100 p-2 rounded-0 border-bottom `}
                >
                  <span>{MuiArticle}</span>
                  <span>{item.title}</span>
                </Link>
              ))}
          </AnimateHeight>
        </div>

        {/* <Link className={`menu-item p-2 rounded ten-step`} to="/signup">
          <span>{Insights}</span>

          <span>{t("business_in")}</span>
        </Link> */}
      </div>
    </Box>
  );
}

export default LandingSidebar;
