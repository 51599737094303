import PricingPage from "../pages/frontend/PricingPage";
import MemberPlan from "../pages/frontend/MemberPlan";
import ForgotPass from "../pages/frontend/ForgotPass";
import ResetPass from "../pages/frontend/ResetPass";
import AdminLogin from "../pages/admin/AdminLogin";
import Success from "../pages/frontend/Success";
import Signup from "../pages/frontend/Signup";
import Login from "../pages/frontend/Login";
import About from "../pages/frontend/About/About";
import Contact from "../pages/frontend/Contact/Contact";
import FAQ from "../pages/frontend/FAQs/FAQs";
import Landing from "../pages/frontend/Landing/Landing";
import LandingChat from "../pages/frontend/Landing/LandingChat";
import LandingPrompt from "../pages/frontend/Landing/LandingPrompt";

const FrontendRoute = [
  { path: "/", name: "Home", component: <LandingChat /> },
  { path: "/insight/:id", name: "Insight", component: <LandingPrompt /> },
  // { path: "/user/chat/prompt", name: "Home", component: <LandingPrompts /> },
  { path: "/login", name: "Login", component: <Login /> },
  { path: "/about", name: "About", component: <About /> },
  { path: "/contact", name: "About", component: <Contact /> },
  { path: "/faqs", name: "About", component: <FAQ /> },
  { path: "/signup", name: "Signup", component: <Signup /> },
  { path: "/payment-success", name: "Success", component: <Success /> },
  { path: "/reset-password", name: "Signup", component: <ResetPass /> },
  { path: "/pricing", name: "PricingPage", component: <PricingPage /> },
  { path: "/forgot-password", name: "Signup", component: <ForgotPass /> },
  { path: "/admin/login", name: "AdminLogin", component: <AdminLogin /> },
  { path: "/member-pricing", name: "MemberPlan", component: <MemberPlan /> },
];

export default FrontendRoute;
